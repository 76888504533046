import './App.css';
import ToDoApp from './components/ToDoApp';


function App() {
  return (
    <div className="App">
      <ToDoApp />
    </div>
  );
}

export default App;
